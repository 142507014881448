<template>
  <div style="width: 100% ;padding: 10px">
    <div style="margin: 10px 0">
    <el-button type="primary" @click="add">添加工件</el-button>
      <el-button type="primary" @click="drawer = true">工序模板管理</el-button>
      <el-button type="primary" @click="gongxuController = true">工序管理</el-button>
      <el-button type="primary" @click="changshangController = true">厂商管理</el-button>
      <el-input style="width: 200px" v-model="selectKey"></el-input>
      <el-button @click="getTableDateShow">筛选</el-button>
      <el-button @click="selectKey='',getTableDateShow()">重置</el-button>
  </div>
    <!--    <div style="margin: 10px 0">
          <el-input v-model="search" placeholder="请输入姓名或工号" style="width:35%"></el-input>
          <el-button type="primary" style="margin-left:5px" @click = "selectkey">查询</el-button>
          <el-button type="primary" @click="remove">重置</el-button>
        </div>-->
    <el-table
        :data="tableDataShow"
        border
        style="width: 100%">
      <el-table-column
          v-if="false"
          fixed="left"
          label="id"
          prop="id"
          width="100">
      </el-table-column>
      <el-table-column
          fixed="left"
          label="工件型号"
          prop="xinghao"
          width="200">
      </el-table-column>
      <el-table-column
          fixed="left"
          label="图纸号"
          prop="tuzhihao"
          width="200">
      </el-table-column>
      <el-table-column
          fixed="left"
          label="厂商"
          prop="changshang"
          width="100">
      </el-table-column>
      <el-table-column
          label="工件数量"
          prop="number"
          width="100">
      </el-table-column>
      <el-table-column
          label="入厂时间"
          prop="data"
          width="130">
      </el-table-column>
      <el-table-column
          label="工序1"
          prop="gongxu[0]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序2"
          prop="gongxu[1]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序3"
          prop="gongxu[2]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序4"
          prop="gongxu[3]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序5"
          prop="gongxu[4]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序6"
          prop="gongxu[5]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序7"
          prop="gongxu[6]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序8"
          prop="gongxu[7]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序9"
          prop="gongxu[8]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序10"
          prop="gongxu[9]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序11"
          prop="gongxu[10]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序12"
          prop="gongxu[11]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序13"
          prop="gongxu[12]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序14"
          prop="gongxu[13]"
          width="80">
      </el-table-column>
      <el-table-column
          label="工序15"
          prop="gongxu[14]"
          width="80">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button size="small" type="text" @click="up(scope.row),upOrNew = 'up'">编辑</el-button>
          <el-popconfirm title="确定删除吗？" @confirm = "deleterow(scope.row.id)">
            <el-button type="text" size="small" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin: 10px 0">
      <el-pagination
          :current-page="currentPage4"
          :page-size="size"
          :page-sizes="[5, 10, 20]"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <!--  编辑弹窗  -->
    <el-dialog :before-close="handleClose2" :visible.sync="dialogVisible" width="50%">
      <el-form :model="form" label-width="80px">
        <el-form-item label="图纸号">
<!--          <el-input :disabled="upOrNew !== 'new'" v-model="form.tuzhihao" @change="createSerious = false" style="width: 90%"></el-input>-->
          <el-select
              v-model="form.tuzhihao"
              filterable
              allow-create
              remote
              reserve-keyword
              placeholder="请输入关键词"
              @change="GetCmdbByPaper"
              :disabled="upOrNew !== 'new'"
              :remote-method="remoteMethod"
              :loading="loading">
            <el-option
                v-for="item in paperList"
                :key="item.value"
                :label="item.value"
                :value="item.value">
            </el-option>
          </el-select>

        </el-form-item>
        <el-form-item label="数量">
          <el-input v-model="form.number" style="width: 90%"></el-input>
        </el-form-item>
        <el-button v-if="upOrNew === 'new'" @click="seriousInpute(form.tuzhihao)">导入工序</el-button>
        <span style="padding-left: 10px;color: red">{{remark}}</span>
        <el-form-item label="型号" v-if="upOrNew !== 'new' || createSerious">
          <el-select
              v-model="form.xinghao"
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="remoteMethod2"
              @change="getCmdbId(form.xinghao)"
              :disabled="!newPaper"
              :loading="loading2">
            <el-option
                v-for="item in xinghaoList"
                :key="item.id"
                :label="item.name"
                :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="自动生成原料出库单" v-if="upOrNew !== 'new' || createSerious">
          <el-switch
              v-model="propertyCostAuthSwitch">
          </el-switch>
        </el-form-item>
        <el-form-item label="厂商" v-if="upOrNew !== 'new' || createSerious">
          <el-select :disabled="upOrNew === 'new' && !isNewSerious" v-model="form.changshang" placeholder="请选择">
            <el-option
                v-for="item in facturyList"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="upOrNew !== 'new' || (createSerious && isNewSerious)" label="加工工序">
          <div style="display:flex">
            <div style="width: 40%">
              <el-timeline>
                <el-timeline-item
                    v-for="(name, index) in gongxu"
                    :key="index">
                  <el-button type="text" @click="optionUpdate = true,optionIndex = index,optionUpdateValue=name">{{ name }}</el-button>
                </el-timeline-item>
              </el-timeline>
              <el-button @click="optionAdd">添加工序</el-button>
            </div>
            <div>
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>工序模板</span>
                </div>
                <div v-for="(muban,i) in gongxumubanList">
                  <el-button type="text" @click="setMuban(muban.processList)">{{ muban.name }}</el-button>
                </div>
              </el-card>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false,gongxu=[],createSerious = false;">取 消</el-button>
        <el-button type="primary" @click="save()" v-if="(upOrNew !== 'new' || createSerious)">确 定</el-button>
      </span>
    </el-dialog>
    <!--  弹窗  -->
    <el-dialog :before-close="handleClose" :visible.sync="optionUpdate" width="50%">
      <div>
        工序删除
        <el-popconfirm title="确定删除吗？" @confirm = "gongxuRemove">
          <el-button type="text" slot="reference">删除</el-button>
        </el-popconfirm>
      </div>
      工序修改：
      <el-select v-model="optionUpdateValue" placeholder="请选择">
        <el-option
            v-for="item in options"
            :key="item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>
      <el-button @click="gongxuUpdate" v-show="optionUpdateValue !== ''">修改</el-button>
    </el-dialog>

    <!--  侧边栏  -->
    <el-drawer
        title="工序模板"
        :visible.sync="drawer"
        :before-close="handleClose">
      <el-card class="box-card">
        <el-button type="primary" @click="gongxumubanAdd">新增</el-button>
        <el-collapse accordion v-for="(item,index) in gongxumubanList">
          <el-collapse-item>
            <template slot="title">
              <div v-if="!gongxumubanbianji">
                {{item.name }}
              </div>
            </template>
            <div style="display:flex">
              <el-button type="primary" v-if="!gongxumubanbianji" @click="gongxumubanbianji = true , gongxumuban=item">编辑</el-button>
              <div v-if="gongxumubanbianji" style="display:flex">
                <div style="width: 50px">名称: </div><el-input v-model="gongxumuban.name"></el-input>
              </div>
              <el-popconfirm title="确定删除吗？" v-if="gongxumubanbianji" @confirm = "delMoban(item.id,index)">
                <el-button type="primary" slot="reference">删除模板</el-button>
              </el-popconfirm>
            </div>
            <el-timeline>
              <el-timeline-item
                  v-for="(name, ind) in item.processList"
                  :key="ind">
                <span v-if="!gongxumubanbianji">{{name}}</span>
                <el-button type="text" v-if="gongxumubanbianji" @click="mubanUpdate = true,mubanIndex = ind,mubanUpdateValue=name,gongxumuban.processList = item.processList">{{name}}</el-button>
              </el-timeline-item>
            </el-timeline>
            <el-button v-if="gongxumubanbianji" @click="gongxuUpdateAdd">添加工序</el-button>
            <el-button type="primary" v-if="gongxumubanbianji" @click="gongxumubanbianjisave">保存</el-button>
          </el-collapse-item>
        </el-collapse>
      </el-card>
    </el-drawer>
    <!--  模板编辑弹窗  -->
    <el-dialog :before-close="handleClose" :visible.sync="mubanUpdate" width="50%">
      <div>
        工序删除
        <el-popconfirm title="确定删除吗？" @confirm = "gongxuMubanRemove">
          <el-button type="text" slot="reference">删除</el-button>
        </el-popconfirm>
      </div>
      工序修改：
      <el-select v-model="mubanUpdateValue" placeholder="请选择">
        <el-option
            v-for="item in options"
            :key="item.process"
            :label="item.process"
            :value="item"
            :disabled="gongxumuban.processList.includes(item.process)">
        </el-option>
      </el-select>
<!--      <el-select v-model="mubanUpdatetap" v-show="mubanUpdateValue.tap.length !== 0" placeholder="请选择">-->
<!--        <el-option-->
<!--            v-for="item in mubanUpdateValue.tap"-->
<!--            :key="item"-->
<!--            :label="item"-->
<!--            :value="item">-->
<!--        </el-option>-->
<!--      </el-select>-->
      <el-button @click="gongxuMubanUpdate" v-show="mubanUpdateValue !== ''">修改</el-button>
    </el-dialog>
    <!--  工序添加弹窗  -->
    <el-dialog title="添加工序" :before-close="handleClose" :visible.sync="gongxuController" width="50%">
      <el-select
          v-model="gongxuAdd"
          filterable
          allow-create
          default-first-option
          placeholder="请输入工序名称"
          @change = "AddTap(gongxuAdd)">
        <el-option
            v-for="item in gongxuOptions"
            :key="item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>
      <el-popconfirm title="工序添加后不可修改或删除，确定添加该工序吗？" v-if='!tapadd' @confirm = "gongxuAddInList">
        <el-button slot="reference">添加</el-button>
      </el-popconfirm>
      <el-select
          v-if='tapadd'
          v-model="gongxuTapAdd"
          filterable
          allow-create
          default-first-option
          placeholder="请输入工序标签">
        <el-option
            v-for="item in tapOptions"
            :key="item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>
      <span v-if='tapOptions != null && tapOptions.includes(gongxuTapAdd)'>工序标签已存在</span>
      <el-popconfirm title="工序标签添加后不可修改或删除，确定添加该工序标签吗？" v-if='tapOptions != null && gongxuTapAdd != null && gongxuTapAdd != "" && !tapOptions.includes(gongxuTapAdd)' @confirm = "gongxuTapAddInList">
        <el-button slot="reference">添加</el-button>
      </el-popconfirm>
    </el-dialog>
    <!--  厂商添加弹窗  -->
    <el-dialog title="添加厂商" :before-close="handleClose" :visible.sync="changshangController" width="50%">
      <el-select
          v-model="changshangAdd"
          filterable
          allow-create
          default-first-option
          placeholder="请输入厂商名称">
        <el-option
            v-for="item in facturyList"
            :key="item"
            :label="item"
            :value="item">
        </el-option>
      </el-select>
      <span v-if='facturyList.includes(changshangAdd)'>厂商已存在</span>
      <el-popconfirm title="厂商添加后不可修改或删除，确定添加改厂商吗？" v-if='!facturyList.includes(changshangAdd)' @confirm = "changshangAddInList">
        <el-button slot="reference">添加</el-button>
      </el-popconfirm>
    </el-dialog>
    <!--  选择原料方案  -->
    <el-dialog title="选择原料方案" :before-close="handleClose" :visible.sync="propertyCostAuthCheck" width="50%">
      <el-select
          v-model="propertyCostId"
          filterable
          default-first-option
          @change="propertyCostAuthCheck=false"
          placeholder="请选择原料方案">
        <el-option
            v-for="item in propertyCostList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
      <span v-if='facturyList.includes(changshangAdd)'>厂商已存在</span>
      <el-popconfirm title="厂商添加后不可修改或删除，确定添加改厂商吗？" v-if='!facturyList.includes(changshangAdd)' @confirm = "changshangAddInList">
        <el-button slot="reference">添加</el-button>
      </el-popconfirm>
    </el-dialog>
  </div>
</template>

<script>

import request from "@/utils/request";
import * as vm from "vm";

export default {
  data() {
    return {
      options: [],
      gongxuOptions:[],
      tapOptions:[],
      tapadd: true,
      gongxuController: false,//工序添加弹窗
      gongxuAdd: {
        process:"",
        tap:[]
      },//添加工序
      gongxuTapAdd: "",//添加工序
      changshangController: false,//厂商添加弹窗
      changshangAdd: "",//添加厂商
      drawer: false,//侧边栏
      mubanUpdate: false,//模板编辑弹窗
      form: {},
      optionUpdate: false,
      optionUpdateValue: "",
      optionTabUpdateList: [],
      optionTabUpdateValue: "",
      optionIndex: 0,
      mubanUpdateValue: {process:'',tap:[]},
      mubanUpdatetap: {},
      mubanIndex: 0,
      dialogVisible: false,
      search: '',
      currentPage4: 1,
      size: 10,
      total: 0,
      tableData: [],
      tableDataShow: [],
      upOrNew: '',
      gongxu: [],
      gongxumubanList:[],//工序模板列标
      facturyList:[],//厂商列标
      gongxumuban: {
        id: 0,
        name: "",
        processList: []
      },
      gongxumubanbianji: false,//模板编辑按钮显隐
      createSerious:false,
      isNewSerious:false,
      remarkList:[],
      remark:"",
      newPaper:false,
      loading: false,
      paperList: [],
      paperListTotal: [],

      loading2: false,
      xinghaoList: [],
      xinghaoListTotal: [],

      propertyCostAuthSwitch:false,

      propertyCostAuthCheck:false,
      propertyCostList:[],
      propertyCostId:0,

      selectKey: ""
    }
  },
  //加载表格
  mounted() {
    this.select()
    this.GetFactury()
    this.GetGongxu()
    this.GetPaperListTotal()
    this.GetXinghaoListTotal()
  },
  watch:{
    propertyCostAuthSwitch(newVal, oldVal){
      if(newVal){
        this.getPropertyCostList()
        this.propertyCostAuthCheck = (this.propertyCostList.length>1)
        if(this.propertyCostList.length === 0){
          this.propertyCostAuthSwitch = false
          this.$notify.error({
            title: '错误',
            message: '当前型号未配置原料'
          });
        }
      } else {
        this.propertyCostList = []
      }
    }
  },
  methods: {
    AddTap(option){
      if (this.gongxuOptions.includes(option)){
        for (let i = 0; i < this.options.length; i++) {
          if (option == this.options[i].process){
            this.tapOptions = this.options[i].tap
            this.tapadd = true
          }
        }
      } else {
        this.tapadd = false
      }
    },
    //新增
    add() {
      this.remark = ""
      this.remarkList = []
      this.dialogVisible = true
      this.form = {}
      this.upOrNew = 'new'
    },
    seriousInpute(tuzhihao){
      this.remark=""
      this.remarkList=[]
      request.post("/isNewSerious",tuzhihao).then(res =>{
        if (res.code === "200"){
          if (res.data.processes.length > 0){
            this.isNewSerious = false;

            let processList = res.data.processes;
            this.form.gongxu = processList;

            this.form.xinghao = res.data.serial.name;
            this.form.tuzhihao = res.data.serial.code;
            this.form.changshang = res.data.serial.factory;
            this.form.serialId = res.data.serial.id;
          } else {
            this.isNewSerious = true;
          }
          this.$forceUpdate();
        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
      this.createSerious = true;
      this.GetRemark(tuzhihao)
    },
    GetRemark(tuzhihao){
      request.post("/getRemark",tuzhihao).then(res =>{
        if (res.code === "200"){
          this.remarkList = res.data
          let num = 0
          for (let i = 0; i < res.data.length; i++) {
            num += res.data[i].scrapNumbers
          }
          if (num !== 0){
            this.remark = "之前批次转结共 " + num + " 件"
          }
        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    gongxuAddInList() {
      if (this.gongxuAdd == {}){
        this.$notify.error({
          title: '错误',
          message: '添加工序为空'
        });
      } else {
        request.post("/AddGongxu",this.gongxuAdd).then(res =>{
          if (res.code == "200"){
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success',
            });
            this.GetGongxu;
            this.gongxuController = false
          }else {
            this.$notify.error({
              title: '错误',
              message: res.code + ":" + res.msg
            });
          }
        })
      }
    },
    gongxuTapAddInList() {
      if (this.gongxuTapAdd ==""){
        this.$notify.error({
          title: '错误',
          message: '添加工序为空'
        });
      } else {
        let pos = {
          process: this.gongxuAdd,
          tap: this.gongxuTapAdd
        }
        request.post("/AddGongxuTap",pos).then(res =>{
          if (res.code == "200"){
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success',
            });
            this.GetGongxu;
            this.gongxuController = false
            this.tapadd = false
          }else {
            this.$notify.error({
              title: '错误',
              message: res.code + ":" + res.msg
            });
          }
        })
      }
    },
    changshangAddInList() {
      if (this.changshangAdd ==""){
        this.$notify.error({
          title: '错误',
          message: '添加工序为空'
        });
      } else {
        request.post("/AddFactury",this.changshangAdd).then(res =>{
          if (res.code == "200"){
            this.$notify({
              title: '成功',
              message: res.data,
              type: 'success',
            });
            this.facturyList.push(this.changshangAdd)
            this.changshangController = false
          }else {
            this.$notify.error({
              title: '错误',
              message: res.code + ":" + res.msg
            });
          }
        })
      }
    },
    getPropertyCostList(){
      request.get("/cmdb-cost/selectByCostId/"+this.form.chanpinCmdbId).then(res =>{
        this.propertyCostList = res.data
      })
    },
    //编辑表单回显
    up(row) {
      this.remark = ""
      this.remarkList = []
      this.form = {
        id: row.id,
        xinghao: row.xinghao,
        tuzhihao: row.tuzhihao,
        changshang: row.changshang,
        number: row.number,
        serialId: row.serialId,
        gongxu: row.gongxu,
      }
      this.gongxu = Array.from(new Set(row.gongxu));
      if(this.gongxu.indexOf("无") !== -1){
        this.gongxu.splice(this.gongxu.indexOf("无"), 1)
      }
      this.dialogVisible = true
    },
    //删除按钮
    deleterow(id) {
      request.post("/ArtifactManageDel",id).then(res =>{
        if (res.code == "200"){
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
          });
          this.select();
        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    //删除按钮
    delMoban(id,index) {
      request.post("/DelMoban",id).then(res =>{
        if (res.code == "200"){
          this.$notify({
            title: '成功',
            message: res.data,
            type: 'success',
          });
          this.gongxumubanList.splice(index, 1)
          this.mubanUpdate = false;
          //this.drawer = false;
          this.gongxumubanbianji = false;
        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    gongxumubanbianjisave() {
      request.post("/MuBanUpdate",this.gongxumuban).then(res =>{
        if (res.code == "200"){
          this.$notify({
            title: '成功',
            message: res.data,
            type: 'success',
          });
          this.gongxumubanbianji = false;
        }else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
      this.GetMoban()
      this.drawer = false
    },
    log(form){
    },
    //保存
    save() {
      this.form.gongxu = this.gongxu;
      if (this.form.xinghao == null || this.form.changshang == null || this.form.tuzhihao == null || this.form.number == null) {
        this.$notify.error({
          title: '错误',
          message: "请录入完整信息"
        });
      } else {
        if (this.upOrNew == 'new') {
          request.post("/AddWorkpiece", this.form).then(res => {
            if (res.code == "200") {
              this.$notify({
                title: '成功',
                message: '修改成功',
                type: 'success'
              });
              //this.tableData.push(this.form)
              this.dialogVisible = false
              this.form = {}
              this.select();
            } else {
              this.$notify.error({
                title: '错误',
                message: res.code + ":" + res.msg
              });
            }
          })
          this.propertyCostAuth();
          /*
          * /CMDB/propertyCost
          * */
        } else if (this.upOrNew == 'up') {
          request.post("/UpdateWorkpiece", this.form).then(res => {
            if (res.code == "200") {
              this.$notify({
                title: '成功',
                message: '编辑成功',
                type: 'success'
              });
              //this.tableData.push(this.form)
              this.dialogVisible = false
              this.form = {}
              this.select();
            } else {
              this.$notify.error({
                title: '错误',
                message: res.code + ":" + res.msg
              });
            }
          })
        } else {
          this.$notify.error({
            title: '错误',
            message: '添加或修改发生意外'
          });
        }
      }
    },
    propertyCostAuth(){
      if (this.propertyCostList.length === 1){
        request.post("/CMDB/propertyCostAuth/"+this.form.chanpinCmdbId+"/"+this.form.number).then(res => {
          if (res.code == "200") {
            this.$notify({
              title: '成功',
              message: '出库单生成成功',
              type: 'success'
            });
            //this.tableData.push(this.form)
            this.dialogVisible = false
            this.form = {}
            this.select();
          } else {
            this.$notify.error({
              title: '错误',
              message: res.code + ":" + res.msg
            });
          }
        })
      } else {
        request.post("/CMDB/propertyCost/"+this.propertyCostId+"/"+this.form.number).then(res => {
          if (res.code == "200") {
            this.$notify({
              title: '成功',
              message: '出库单生成成功',
              type: 'success'
            });
            //this.tableData.push(this.form)
            this.dialogVisible = false
            this.form = {}
            this.select();
          } else {
            this.$notify.error({
              title: '错误',
              message: res.code + ":" + res.msg
            });
          }
        })
      }
    },
    //整表查询
    select() {
      request.post("/ArtifactManage").then(res => {
        this.total = res.data.length;
        this.tableData = res.data
        this.getTableDateShow();
      })
      this.GetMoban()
    },
    GetMoban(){
      request.post("/GetMoban").then(res => {
        this.gongxumubanList = res.data
      })
    },
    GetPaperListTotal(){
      request.post("/CMDB/getPaperList").then(res => {
        this.paperListTotal = res.data
      })
    },
    GetXinghaoListTotal(){
      request.post("/CMDB/getPropertyChildren","产品列表").then(res => {
        this.xinghaoListTotal = res.data
      })
    },
    GetCmdbByPaper(){
      this.createSerious = false
      request.post("/CMDB/GetCmdbByPaper",this.form.tuzhihao).then(res => {
        let _cmdb = res.data
        if (this.isEmptyString(_cmdb.name)){
          this.newPaper = true;
        } else {
          this.newPaper = false;
          this.form.xinghao = _cmdb.name
          this.form.chanpinCmdbId = _cmdb.id
        }
      })
    },
    GetGongxu() {
      request.post("/GetGongxu").then(res => {
        this.options = res.data
        if (this.options.length>0){
          this.gongxuAdd = this.options[0];
          let gongxuOption = []
          for (let i = 0; i < this.options.length; i++) {
            gongxuOption.push(this.options[i])
          }
          this.gongxuOptions = gongxuOption;
        }
      })
    },
    GetFactury(){
      request.post("/GetFactury").then(res => {
        this.facturyList = res.data;
      })
    },
    setMuban(list) {
      this.gongxu = list
    },
    //关键字查询
    // selectkey() {
    //   if (parseInt(this.search) > 0) {
    //     request.post("/EmployeeAuthoritySelectId", parseInt(this.search)).then(res => {
    //       this.tableData = res.data
    //     })
    //   } else {
    //     request.post("/EmployeeAuthoritySelectName", this.search).then(res => {
    //       this.tableData = res.data
    //     })
    //   }
    // },
    //编辑
    update() {
      request.post("/EmployeeAuthority", this.form).then(res => {
        if (res.code == "200") {
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success'
          });
          this.dialogVisible = false
          this.form = {}
          this.select();
        } else {
          this.$notify.error({
            title: '错误',
            message: res.code + ":" + res.msg
          });
        }
      })
    },
    //重置
    remove() {
      this.search = ''
      this.select();
    },
    handleClose2(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            this.createSerious = false;
            done();
          })
          .catch(_ => {
          });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {
          });
    },
    handleSizeChange(val) {
      this.size = val;
      this.getTableDateShow();
    },
    handleCurrentChange(val) {
      this.currentPage4 = val
      this.getTableDateShow();
    },

    getTableDateShow(){

      let _tableData = this.tableData.concat()
      this.total = _tableData.length
      if (this.selectKey !== ""){
        _tableData = _tableData.filter(item => (item.xinghao.includes(this.selectKey) || item.tuzhihao.includes(this.selectKey)))

        this.total = _tableData.length
      } else {
        _tableData = _tableData
      }

      let _pageMax = Math.ceil(this.total/this.size)
      this.currentPage4 = this.currentPage4>_pageMax ? _pageMax :this.currentPage4;
      this.currentPage4 = this.currentPage4 < 1 ? 1 : this.currentPage4

      this.tableDataShow = _tableData.slice((this.currentPage4-1)*this.size,this.currentPage4*this.size);
    },
    handleEdit(row) {
    },
    optionAdd() {
      this.optionUpdateValue = ""
      this.optionIndex = this.gongxu.length
      this.optionUpdate = true
    },
    gongxuUpdateAdd() {
      this.mubanUpdateValue = ""
      if(this.gongxumuban.processList !== undefined && this.gongxumuban.processList !== null){
        this.mubanIndex = this.gongxumuban.processList.length
      } else {
        this.mubanIndex = 0;
      }
      this.mubanUpdate = true
    },
    gongxuUpdate() {
      if (this.optionIndex == this.gongxu.length){
        this.gongxu.push(this.optionUpdateValue)
        this.optionUpdate = false
      }else {
        this.gongxu.splice(this.optionIndex, 1,this.optionUpdateValue)
        this.optionUpdate = false
      }
    },
    gongxuMubanUpdate() {
      if (this.mubanUpdateValue == null || this.mubanUpdateValue.process == ""){
        this.$notify.error({
          title: '错误',
          message: '请选择工序'
        });
      } else {
        if (this.mubanIndex == this.gongxumuban.processList.length){
          this.gongxumuban.processList.push(this.mubanUpdateValue)
          this.mubanUpdate = false
        }else {
          this.gongxumuban.processList.splice(this.mubanIndex, 1,this.mubanUpdateValue)
          this.mubanUpdate = false
        }
      }
    },
    gongxuRemove() {
      this.gongxu.splice(this.optionIndex, 1)
      this.optionUpdate = false
    },
    gongxumubanAdd() {
      let l = {
        id: -1,
        name: "新建的工序模板",
        processList:[]
      }
      this.gongxumubanList.push(l)
    },
    gongxuMubanRemove() {
      this.gongxumuban.processList.splice(this.mubanIndex, 1)
      this.mubanUpdate = false
    },
    isEmptyString(str){
      return (str === "" || str === null || str === undefined)
    },
    getCmdbId(name){
      let _cmdb = this.xinghaoListTotal.find(item => {
        return item.name !== null && item.name !== undefined && (item.name === name);
      });
      this.form.chanpinCmdbId = _cmdb.id
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        this.paperList = this.paperListTotal.filter(item => {
          return item.value !== null && item.value !== undefined && (item.value.toLowerCase()
              .indexOf(query.toLowerCase()) > -1);
        });
        this.$nextTick(()=>{
          this.loading = false;
        })
      } else {
        this.paperList = [];
      }
    },

    remoteMethod2(query) {
      if (query !== '') {
        this.loading2 = true;
        this.xinghaoList = this.xinghaoListTotal.filter(item => {
          return item.name !== null && item.name !== undefined && (item.name.toLowerCase()
              .indexOf(query.toLowerCase()) > -1);
        });
        this.$nextTick(()=>{
          this.loading2 = false;
        })
      } else {
        this.xinghaoList = [];
      }
    }
  }

}

</script>

<style scoped>

.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  width: 300px;
}
</style>